<template>
  <div class="newList">
    <div class="content">
      <el-row :gutter="20" >
        <el-col :xs="24" :sm="24" :md="18" :lg="18" :xl="18">
          <router-view />
        </el-col>
        <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
          <rightBox/>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
  import rightBox from './components/right.vue';
  export default {
  name: "newsPage",
  components:{rightBox},
  data() {
    return {
      
      
      }
  },
  methods: {
    handleSizeChange(val) {
    },
    handleCurrentChange(val) {
    }
    }
  }
</script>
<style lang="less" scoped>
  .moblile{
    .newList{
      padding-bottom: 0;
    } 
  } 
</style>
