<template>
  <div>
    <div class="rightBox">
        <h3>热门资讯</h3>
<!--        <img src="@/assets/images/new_right_img.png" alt="" class="img">-->
        <ul>
          <li><p></p></li>
        <li v-for="(list, index) in rightList" class="flex" @click="goTo(list.id)">
            <img src="@/assets/images/new_radio.png" alt="" class="icon">
            {{ list.title }}
        </li>
        </ul>
    </div>
    <div class="rightBox">
        <h3>专题导读</h3>
        <img src="@/assets/images/peixun.jpg" alt="" class="img">
        <ul>
        <li v-for="(list, index) in specialColumn" class="flex" @click="goTo(list.id)">
          <template v-if="index == 0">
            <p>{{ list.title }}</p>
          </template>
          <template v-else>
            <img src="@/assets/images/new_radio.png" alt="" class="icon">
            {{ list.title }}
          </template>
        </li>
        </ul>
    </div>
<!--    <div class="rightBox">-->
<!--        <h3>专题导读</h3>-->
<!--        <img src="@/assets/images/new_right_img.png" alt="" class="img">-->
<!--        <ul>-->
<!--        <li v-for="(list, index) in rightList" class="flex" @click="$router.push({name:'NewsInfo', query:{id:list.id}})">-->
<!--          <template v-if="index == 0">-->
<!--            <p>{{ list.title }}</p>-->
<!--          </template>-->
<!--          <template v-else>-->
<!--            <img src="@/assets/images/new_radio.png" alt="" class="icon">-->
<!--            {{ list.title }}-->
<!--          </template>-->
<!--        </li>-->
<!--        </ul>-->
<!--    </div>-->
  </div>
</template>

<script>
  export default {
  name: "rightPage",
  data() {
    return {
      id: 0,
      rightList: [],
      specialColumn: [
        {'id': 34, 'title': '员工培训体系如何建立？'},
        {'id': 32, 'title': '企业培训的目的是什么？'},
        {'id': 33, 'title': '企业培训的十条军规，教你怎样不花一分钱把培训做好'},
        {'id': 35, 'title': '公司培训方式有几种形式-培训的五种形式'},
        {'id': 29, 'title': '员工培训14种最佳交付方式'},
      ]
    }
  },
    mounted() {
      this.getNewsHot()
    },
    methods: {
      getNewsHot() {
        this.$store.dispatch("getHotsNews").then((res) => {
          this.rightList = res.data;
        });
      },
      goTo(id){
        if(this.$route.name == 'NewsInfo'){
          this.$router.go({name:'NewsInfo', query:{id:id}})
        }else{
          this.$router.push({name:'NewsInfo', query:{id:id}})
        }
      }
    }
  }
</script>

<style lang="less" scoped>

</style>
